<template>
    <component :is="isLink ? 'Link' : 'button'"
               :type="!isLink ? type : null"
               :href="href"
               class="inline-flex items-center border shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
               :class="[colorClasses, sizeClasses, disabledClasses]"
               :disabled="disabled || loading"
               ref="button"
    >
        <font-awesome-icon v-if="loading" class="-ml-1 mr-3" spin :icon="['fa', 'spinner']"/>
        <slot/>
    </component>
</template>
<script setup>
import {computed, ref} from 'vue'

let props = defineProps({
    type: {
        type: String,
        default: 'submit',
        validator: value => ['button', 'submit', 'link'].includes(value)
    },
    href: {
        type: String,
        required: false
    },
    size: {
        type: String,
        default: 'sm'
    },
    color: {
        type: String,
        default: 'primary'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const isLink = computed(() => props.href || props.type === 'link');
const colorClasses = computed(() => {
    switch (props.color) {
        case 'secondary':
            return 'text-white bg-secondary-600 hover:bg-secondary-700 focus:ring-secondary-500 border-transparent';
        case 'indigo':
            return 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 border-transparent';
        case 'success':
            return 'text-white bg-success-600 hover:bg-success-700 focus:ring-success-500 border-transparent';
        case 'green':
            return 'text-white bg-green-600 hover:bg-green-700 focus:ring-green-500 border-transparent';
        case 'warning':
            return 'text-white bg-warning-600 hover:bg-warning-700 focus:ring-warning-500 border-transparent';
        case 'yellow':
            return 'text-white bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 border-transparent';
        case 'error':
        case 'danger':
            return 'text-white bg-error-600 hover:bg-error-700 focus:ring-error-500 border-transparent';
        case 'red':
            return 'text-white bg-red-600 hover:bg-red-700 focus:ring-red-500 border-transparent';
        case 'primary':
            return 'text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 border-transparent';
        case 'blue':
            return 'text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 border-transparent';
        case 'info':
            return 'text-white bg-info-600 hover:bg-info-700 focus:ring-info-500 border-transparent';
        case 'teal':
            return 'text-white bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 border-transparent';
        case 'dark':
            return 'text-white bg-gray-800 border-transparent hover:bg-gray-700 active:bg-gray-900 focus:border-gray-900 focus:shadow-outline-gray';
        case 'light':
            return 'text-gray-700 border-transparent bg-gray-300 border-gray-400 hover:border-gray-500 hover:bg-gray-200 focus:ring-gray-500';
        case 'white':
            return 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:ring-gray-500';
        default:
            return props.color;
    }
});
const sizeClasses = computed(() => {
    switch (props.size) {
        case 'xs':
            return 'px-2.5 py-1.5 text-xs rounded';
        case 'sm':
            return 'px-3 py-2 text-sm rounded-md';
        case 'md':
            return 'px-4 py-2 text-sm rounded-md';
        case 'lg':
            return 'px-4 py-2 text-base rounded-md';
        case 'xl':
            return 'px-6 py-3 text-base rounded-md';
        default:
            return props.size;
    }
});
const disabledClasses = computed(() => {
    if (props.loading) {
        return 'opacity-50 cursor-wait';
    }
    if (props.disabled) {
        return 'opacity-50 cursor-not-allowed';
    }

    return '';
});

const button = ref(null)

defineExpose({
    button
})
</script>
