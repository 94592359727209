<template>
    <div>
        <div v-if="breadcrumbs || $slots.breadcrumbs" class="mb-2">
            <Breadcrumbs :breadcrumbs="breadcrumbs" class="hidden sm:flex"/>
        </div>
        <div class="mb-4 pb-4 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
            <h1 v-if="title || $slots.default" class="text-lg text-2xl font-semibold text-gray-900">
                <slot>{{ title }}</slot>
            </h1>
            <div v-if="$slots.actions" class="flex space-x-2" :class="{'mt-3 sm:mt-0 sm:ml-4': title || $slots.default}">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
    components: {Breadcrumbs},
    props: {
        title: {
            type: String,
            required: false
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        }
    }
}
</script>

