<template>
    <Head>
        <title>{{ siteTitle }}</title>
        <meta head-key="og:title" property="og:title" :content="siteTitle"/>
        <slot></slot>
    </Head>
</template>

<script>
import {Head} from '@inertiajs/inertia-vue3'
export default {
    components: {Head},
    props: {
        title: {
            type: String,
            required: false
        },
        replace: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        siteTitle() {
            if (!this.title) {
                return this.$page.props.site.name;
            }
            if (this.replace) {
                return this.title;
            }
            return this.title + ' | ' + this.$page.props.site.name;
        }
    }
}
</script>

<style scoped>

</style>
