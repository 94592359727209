<template>
    <div class="h-full">
        <MobileSidebar v-if="isAdmin" :open="open" @close="open = false" @open="open = true" @toggle="open = !open"/>
        <Sidebar v-if="isAdmin"/>
        <div class="h-full flex flex-col flex-1" :class="{'md:pl-64': isAdmin}">
            <SiteHeader :is-admin="isAdmin" :open="open" @close="open = false" @open="open = true" @toggle="open = !open"/>
            <main class="flex-1">
                <div class="py-6">
                    <div class="px-4 sm:px-6 md:px-8">
                        <FlashMessages/>
                        <slot></slot>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import {Inertia} from '@inertiajs/inertia'
import SiteHeader from '@/partials/SiteHeader.vue'
import Sidebar from '@/partials/Sidebar.vue'
import MobileSidebar from '@/partials/MobileSidebar.vue'
import FlashMessages from '@/components/FlashMessages.vue'

export default {
    components: {
        SiteHeader,
        Sidebar,
        MobileSidebar,
        FlashMessages
    },
    computed: {
        role() {
            return this.$page.props.auth?.user?.role || 'client';
        },
        isAdmin() {
            return this.role === 'admin';
        }
    },
    data() {
        return {
            open: false
        };
    },
    mounted() {
        Inertia.on('navigate', (event) => {
            this.open = false;
        })
    }
}
</script>
