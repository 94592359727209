<template>
    <Link :href="href" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
        <slot name="icon" :active="active" :icon="icon">
            <component :is="icon" class="mr-3 flex-shrink-0 h-6 w-6 text-gray-500" aria-hidden="true" />
        </slot>
        <slot>{{title}}</slot>
    </Link>
</template>

<script>
export default {
    props:{
        href: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        icon: {
            required:false
        },
        title: {
            type: String,
            required: false
        }
    }
}
</script>
