import './plugins/axios'
import './plugins/echo'
import {createApp, h} from 'vue'
import {createInertiaApp} from '@inertiajs/inertia-vue3'
import FontAwesomeIcon from '@/plugins/fonts';
import {InertiaProgress} from '@inertiajs/progress'
import Layout from '@/Layouts/Main.vue';
import MetaTags from '@/components/MetaTags.vue';
import PageTitle from '@/components/PageTitle.vue';
import Btn from '@/components/Btn.vue';
import { Link } from '@inertiajs/inertia-vue3'
import '@css/app.css';

createInertiaApp({
    resolve: async name => {
        if(!name) return;
        const page = await import(`@/Pages/${name}.vue`)
        page.layout = page.layout || Layout
        return page
    },
    setup({el, App, props, plugin}) {
        createApp({render: () => h(App, props)})
            .use(plugin)
            .mixin({ methods: { route } })
            .component('Link', Link)
            .component('btn', Btn)
            .component('meta-tags', MetaTags)
            .component('page-title', PageTitle)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount(el)
    },
})

InertiaProgress.init();



