<template>
    <NavigationLink v-if="true" :href="route('admin.website')" :active="section === 'Websites'">
        <template #icon="{active}">
            <font-awesome-icon :icon="['fas', 'link']" :class="[active ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
        </template>
        Websites
    </NavigationLink>
    <NavigationLink v-if="true" :href="route('admin.account')" :active="section === 'Accounts'">
        <template #icon="{active}">
            <font-awesome-icon :icon="['fas', 'building']" :class="[active ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
        </template>
        Accounts
    </NavigationLink>

    <NavigationLink v-if="true" :href="route('admin.user')" :active="section === 'Users'">
        <template #icon="{active}">
            <font-awesome-icon :icon="['fas', 'users']" :class="[active ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
        </template>
        Users
    </NavigationLink>
</template>

<script>
import NavigationLink from '@/components/NavigationLink.vue';
import {ChartBarIcon} from '@heroicons/vue/outline'

export default {
    components: {NavigationLink, ChartBarIcon},
    props: {
        section: {
            type: String,
            required: true
        },
        permissions: {
            type: Object,
            required: true
        }
    }
}
</script>

