<template>
    <div v-if="messages.length > 0" class="mb-4 grid grid-cols-1 gap-2">
        <Alert v-for="(message, index) in messages"
               :type="message.type"
               :message="message.message"
               dismissible
               bubble-dismiss
               @dismiss="dismiss(index)"
        />
    </div>
</template>

<script>
import Alert from '@/components/Alert.vue'

export default {
    components: {Alert},
    computed: {
        messages() {
            return this.$page.props?.flash || [];
        },
    },
    methods: {
        dismiss(index) {
            this.$page.props.flash.splice(index, 1);
        }
    }
}
</script>
