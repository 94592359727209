<template>
    <nav>
        <NavigationLink :href="route('dashboard')" :active="section === 'Dashboard'">
            <template #icon="{active}">
                <HomeIcon :class="[active ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true"/>
            </template>
            Dashboard
        </NavigationLink>
        <admin-navigation :section="section" :permissions="$page.props.auth.permissions" />
    </nav>
</template>

<script>
import NavigationLink from '@/components/NavigationLink.vue';
import AdminNavigation from '@/partials/AdminNavigation.vue';
import {HomeIcon, ChartBarIcon} from '@heroicons/vue/outline'

export default {
    components: {HomeIcon, NavigationLink, ChartBarIcon, AdminNavigation},
    computed: {
        section() {
            if (this.$page.props.section) {
                return this.$page.props.section;
            }
            return this.$page.component;
        }
    }
}
</script>
