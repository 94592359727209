<template>
    <img class="h-8 w-auto"
         :src="logo"
         alt="Digital Canvas WordPress Dashboard"/>
</template>

<script>
import Logo from '@img/digitalcanvas-logo.gif'
export default {
    data(){
        return {
            logo: Logo
        }
    }
}
</script>

