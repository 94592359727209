import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faSort,
    faSortDown,
    faSortUp,
    faUserTie,
    faSpinner,
    faTriangleExclamation,
    faCircleExclamation,
    faPause,
    faPlay,
    faChevronCircleUp,
    faChevronCircleDown,
    faPhone,
    faUser,
    faStopwatch,
    faLink,
    faUsers,
    faBuilding,
    faPaperclip
} from "@fortawesome/free-solid-svg-icons";
// import {} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(
    faSort, faSortDown, faSortUp, faUserTie,
    faSpinner, faTriangleExclamation, faCircleExclamation, faPlay, faPause,
    faChevronCircleUp, faChevronCircleDown, faPhone, faPaperclip, faUser,
    faBuilding, faUsers, faLink, faStopwatch,
);

export default FontAwesomeIcon;
