<template>
    <nav v-if="breadcrumbs.length > 0" class="flex" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-4">
            <li>
                <div>
                    <Link :href="route('dashboard')" class="text-gray-400 hover:text-gray-500">
                        <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </Link>
                </div>
            </li>
            <li v-for="breadcrumb in breadcrumbs" :key="breadcrumb.name">
                <div class="flex items-center">
                    <ChevronRightIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                    <Link :as="breadcrumb.href ? 'a': 'span'" :href="breadcrumb.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="breadcrumb.current ? 'page' : undefined">{{ breadcrumb.name }}</Link>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
import { ChevronRightIcon, HomeIcon } from '@heroicons/vue/solid'

export default {
    components: {
        ChevronRightIcon,
        HomeIcon,
    },
    props: {
        breadcrumbs: {
            type: Array,
            default: () => []
        }
    }
}
</script>
