<template>
    <div v-if="!hidden && modelValue" class="rounded-md p-4" :class="wrapperClasses">
        <div class="flex">
            <div :class="textClasses">
                <slot name="header">
                    <div v-if="title || $slots.title" class="font-bold text-lg">
                        <slot name="title">{{ title }}</slot>
                    </div>
                </slot>
                <div class="text-sm" >
                    <slot>{{ message }}</slot>
                </div>
                <div v-if="$slots.actions" class="mt-4 flex justify-start items-start flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                    <slot name="actions"></slot>
                </div>
            </div>
            <div v-if="dismissible" class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button @click.prevent="dismiss" type="button"
                            class="inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2"
                            :class="buttonClasses"
                    >
                        <span class="sr-only">Dismiss</span>
                        <XIcon class="h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {XIcon} from '@heroicons/vue/solid'

export default {
    components: {XIcon},
    props: {
        type: {
            type: String,
            default: 'primary'
        },
        title: {
            type: String,
            required: false
        },
        message: {
            type: String,
            required: false
        },
        dismissible: {
            type: Boolean,
            default: false,
        },
        bubbleDismiss: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        buttonClasses() {
            switch (this.type) {
                case 'primary':
                    return 'bg-primary-50 text-primary-500 hover:bg-primary-100 focus:ring-offset-primary-50 focus:ring-primary-600';
                case 'success':
                    return 'bg-success-50 text-success-500 hover:bg-success-100 focus:ring-offset-success-50 focus:ring-success-600';
                case 'info':
                    return 'bg-info-50 text-info-500 hover:bg-info-100 focus:ring-offset-info-50 focus:ring-info-600';
                case 'warning':
                    return 'bg-warning-50 text-warning-500 hover:bg-warning-100 focus:ring-offset-warning-50 focus:ring-warning-600';
                case 'error':
                    return 'bg-error-50 text-error-500 hover:bg-error-100 focus:ring-offset-error-50 focus:ring-error-600';
                case 'secondary':
                    return 'bg-secondary-50 text-secondary-500 hover:bg-secondary-100 focus:ring-offset-secondary-50 focus:ring-secondary-600';
                case 'light':
                    return 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-offset-gray-50 focus:ring-gray-600';
                case 'dark':
                    return 'bg-gray-50 text-gray-500 hover:bg-gray-100 focus:ring-offset-gray-50 focus:ring-gray-600';
            }
        },
        wrapperClasses() {
            switch (this.type) {
                case 'primary':
                    return 'bg-primary-50';
                case 'success':
                    return 'bg-success-50';
                case 'info':
                    return 'bg-info-50';
                case 'warning':
                    return 'bg-warning-50';
                case 'error':
                    return 'bg-error-50';
                case 'secondary':
                    return 'bg-secondary-50';
                case 'light':
                    return 'bg-gray-50';
                case 'dark':
                    return 'bg-gray-50';
            }
        },
        textClasses() {
            switch (this.type) {
                case 'primary':
                    return 'text-primary-700';
                case 'success':
                    return 'text-success-700';
                case 'info':
                    return 'text-info-700';
                case 'warning':
                    return 'text-warning-700';
                case 'error':
                    return 'text-error-700';
                case 'secondary':
                    return 'text-secondary-700';
                case 'light':
                    return 'text-gray-700';
                case 'dark':
                    return 'text-gray-700';
            }
        },
    },
    data() {
        return {
            hidden: false
        }
    },
    methods: {
        dismiss() {
            if (!this.bubbleDismiss) {
                this.hidden = true;
            }
            this.$emit('dismiss');
            this.$emit('update:modelValue', false);
        }
    }
}
</script>
